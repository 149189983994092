<template>
    <div class="sm:col-span-2">
        <BaseInput
            v-model="categoryData.name"
            :label="$t('categories.name')"
            :name="$t('categories.name')"
            :placeholder="$t('categories.name')"
            :disabled="loading"
            rules="required|max:255"
            required />
    </div>
    <div class="sm:col-span-2">
        <BaseSearchSelect
            v-model="categoryData.parent_category_id"
            :label="$t('categories.parentCategory')"
            api-route="spa.basedata.categories.index"
            :filter="filter"
            :required="false" />
    </div>
    <div class="sm:col-span-2">
        <div class="font-bold">
            {{ $t('categories.inheritable') }}
        </div>
        <p class="mb-1">
            Wenn eine Kategorie vererbt werden soll, werden auch alle übergeordneten Kategorien vererbt.<br>
        </p>
        <p class="mb-3">
            Sobald eine vererbbare Kategorie mit einer Organisation verknüpft wird, wird diese Kategorie auch mit allen
            untergeordneten Organisationen verknüpft.
        </p>
        <BaseSwitch
            v-model="categoryData.inheritable"
            :name="$t('categories.inheritable')"
            :label="$t('categories.inheritable')" />
    </div>
</template>
<script setup>
import {useRoute} from "vue-router";

const {t: $t} = useI18n()

const route = useRoute()

const filter = computed(() => {
    const filter = [];
    if (categoryData.value.id) {
        filter.push({
            name: 'withoutSelf',
            column: 'id',
            operator: '!=',
            value: categoryData.value.id,
        })
    }

    return filter
})

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    loading: {
        type: Boolean,
        required: true,
        default: false
    }
})

const categoryData = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

</script>
